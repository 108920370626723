:root {
  --border-sm: 6px;
  --border-md: 12px;
  --border-lg: 32px;

  /* -------------------- colors -------------------- */
  --primary-50: rgba(235, 243, 254, 1);
  --primary-100: #c2d8fc;
  --primary-200: #a5c6fb;
  --primary-300: #7cabf9;
  --primary-400: #629bf8;
  --primary-500: #3b82f6;
  --primary-600: #3676e0;
  --primary-700: #2a5caf;
  --primary-800: #204887;
  --primary-900: #193767;

  --opacity-primary8: rgba(59, 130, 246, 0, 08);
  --opacity-primary16: rgba(59, 130, 246, 0.016);
  --opacity-primary24: rgba(59, 130, 246, 0.024);
  --opacity-primary32: rgba(59, 130, 246, 0.032);
  --opacity-primary40: rgba(59, 130, 246, 0.4);
  --opacity-primary48: rgba(59, 130, 246, 0.48);

  --secondary-100: #eeeeef;
  --secondary-200: #dcdddf;
  --secondary-300: #cbccce;
  --secondary-400: #b9bbbe;
  --secondary-500: #a8aaae;
  --secondary-600: #98999d;
  --secondary-700: #8f9194;
  --secondary-800: #86888b;
  --secondary-900: #7e7f82;

  --opacity-secondary8: rgba(168, 170, 174, 0, 08);
  --opacity-secondary16: rgba(168, 170, 174, 0.016);
  --opacity-secondary24: rgba(168, 170, 174, 0.024);
  --opacity-secondary32: rgba(168, 170, 174, 0.032);
  --opacity-secondary40: rgba(168, 170, 174, 0.4);
  --opacity-secondary48: rgba(168, 170, 174, 0.48);

  --grey-50: rgba(249, 250, 251, 1);
  --grey-100: rgba(242, 244, 247, 1);
  --grey-200: rgba(234, 236, 240, 1);
  --grey-300: rgba(208, 213, 221, 1);
  --grey-400: rgba(152, 162, 179, 1);
  --grey-500: rgba(102, 112, 133, 1);
  --grey-600: rgba(71, 84, 103, 1);
  --grey-700: rgba(52, 64, 84, 1);
  --grey-800: rgba(29, 41, 57, 1);
  --grey-900: rgba(16, 24, 40, 1);

  --success-100: rgba(212, 244, 226, 1);
  --success-200: rgba(169, 233, 197, 1);
  --success-300: rgba(126, 221, 169, 1);
  --success-400: rgba(83, 210, 140, 1);
  --success-500: rgba(40, 199, 111, 1);
  --success-600: rgba(36, 179, 100, 1);
  --success-700: rgba(34, 169, 94, 1);
  --success-800: rgba(32, 159, 89, 1);
  --success-900: rgba(30, 149, 83, 1);

  --opacity-success8: rgba(40, 199, 111, 0, 08);
  --opacity-success16: rgba(40, 199, 111, 0.016);
  --opacity-success24: rgba(40, 199, 111, 0.024);
  --opacity-success32: rgba(40, 199, 111, 0.032);
  --opacity-success40: rgba(40, 199, 111, 0.4);
  --opacity-success48: rgba(40, 199, 111, 0.48);

  --warning-100: rgba(255, 236, 217, 1);
  --warning-200: rgba(255, 217, 180, 1);
  --warning-300: rgba(255, 197, 142, 1);
  --warning-400: rgba(255, 178, 105, 1);
  --warning-500: rgba(255, 159, 67, 1);
  --warning-600: rgba(230, 143, 60, 1);
  --warning-700: rgba(217, 135, 57, 1);
  --warning-800: rgba(204, 127, 54, 1);
  --warning-900: rgba(191, 119, 50, 1);

  --opacity-warning8: rgba(255, 159, 67, 0, 08);
  --opacity-warning16: rgba(255, 159, 67, 0.016);
  --opacity-warning24: rgba(255, 159, 67, 0.024);
  --opacity-warning32: rgba(255, 159, 67, 0.032);
  --opacity-warning40: rgba(255, 159, 67, 0.4);
  --opacity-warning48: rgba(255, 159, 67, 0.48);

  --danger-100: rgba(251, 221, 221, 1);
  --danger-200: rgba(247, 187, 187, 1);
  --danger-300: rgba(242, 152, 153, 1);
  --danger-400: rgba(238, 118, 119, 1);
  --danger-500: rgba(234, 84, 85, 1);
  --danger-600: rgba(211, 76, 77, 1);
  --danger-700: rgba(199, 71, 72, 1);
  --danger-800: rgba(187, 67, 68, 1);
  --danger-900: rgba(175, 63, 64, 1);

  --opacity-danger8: rgba(234, 84, 85, 0, 08);
  --opacity-danger16: rgba(234, 84, 85, 0.016);
  --opacity-danger24: rgba(234, 84, 85, 0.024);
  --opacity-danger32: rgba(234, 84, 85, 0.032);
  --opacity-danger40: rgba(234, 84, 85, 0.4);
  --opacity-danger48: rgba(234, 84, 85, 0.48);

  --info-100: rgba(204, 245, 250, 1);
  --info-200: rgba(153, 236, 246, 1);
  --info-300: rgba(102, 226, 241, 1);
  --info-400: rgba(51, 217, 237, 1);
  --info-500: rgba(0, 207, 232, 1);
  --info-600: rgba(0, 187, 209, 1);
  --info-700: rgba(0, 176, 197, 1);
  --info-800: rgba(0, 166, 186, 1);
  --info-900: rgba(0, 155, 174, 1);

  --dark-theme-primary: rgba(37, 41, 60, 1);
  --dark-theme-secondary: rgba(47, 51, 73, 1);
  --dark-theme-text: rgba(134, 146, 208, 1);

  --opacity-info8: rgba(0, 207, 232, 0, 08);
  --opacity-info16: rgba(0, 207, 232, 0.016);
  --opacity-info24: rgba(0, 207, 232, 0.024);
  --opacity-info32: rgba(0, 207, 232, 0.032);
  --opacity-info40: rgba(0, 207, 232, 0.4);
  --opacity-info48: rgba(0, 207, 232, 0.48);

  --extra-cardBackground: rgba(255, 255, 255, 1);
  --extra-bodyBackground: rgba(248, 247, 250, 1);
  --extra-divider: rgba(219, 218, 222, 1);
  --extra-light: rgba(223, 223, 227, 1);
  --extra-dark: rgba(75, 75, 75, 1);
  --extra-white: rgba(255, 255, 255, 1);
  --extra-tableStriped: rgba(75, 70, 92, 0.02);
  --extra-headerBackground: rgba(236, 236, 238, 1);
  --extra-transparent: rgba(255, 255, 255, 0);

  --typography-heading: rgba(24, 28, 50, 1);
  --typography-body: rgba(63, 66, 84, 1);
  --typography-muted: rgba(126, 130, 153, 1);
  --typography-placeholder: rgba(161, 165, 183, 1);

  --opacity-extra-menuOpen: rgba(75, 70, 92, 0.04);
  --opacity-extra-tableStriped: rgba(75, 70, 92, 0.03);
  --opacity-extra-menuHover: rgba(75, 70, 92, 0.04);
  --opacity-extra-dark16: rgba(75, 75, 75, 0.16);
  --opacity-extra-overlay: rgba(75, 70, 92, 0.68);

  --white: #fff;
  --black: #000000;

  /* -------------------- gradient -------------------- */
  --gradient-menu: linear-gradient(72.47deg, #3676e0 22.16%, rgba(54, 118, 224, 0.7) 76.47%);
  --gradient-main: linear-gradient(90deg, rgba(1, 135, 128, 1) 0%, rgba(0, 215, 203, 1) 100%);
  --gradient-Success: linear-gradient(90deg, rgba(40, 199, 111, 1) 0%, rgba(72, 218, 137, 1) 100%);
  --gradient-Danger: linear-gradient(90deg, rgba(234, 84, 85, 1) 0%, rgba(240, 129, 130, 1) 100%);
  --gradient-Warning: linear-gradient(90deg, rgba(255, 159, 67, 1) 0%, rgba(255, 185, 118, 1) 100%);

  /* -------------------- shadow -------------------- */
  --shadow-1: 0px 2px 32px 0px rgba(0, 0, 0, 0.08);
}

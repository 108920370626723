.loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #3b82f6;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.small {
  width: 20px;
  height: 20px;
}
.normal {
  width: 32px;
  height: 32px;
}
.large {
  width: 42px;
  height: 42px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

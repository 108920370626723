* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: all 0.3s;
}

:root {
  --font-size: 16px; 
}

html,
body {
  max-width: 100vw;
  overflow-x: auto;
  padding: 0;
  margin: 0;
  font-family: Montserrat;
  scrollbar-width: none;
  font-size: var(--font-size);
}

a {
  color: inherit;
  text-decoration: none;
}

input,
textarea {
  outline: none;
  border: 0;
}

input:focus,
textarea:focus {
  outline: none;
}

button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  user-select: none;
}

li {
  list-style: none;
}

